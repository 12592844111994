<template>
  <div>
    <public-header />

    <div class="site-content pt-3">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="card mb-3">
              <div class="card-body">
                <h5>Get In Touch</h5>
                <p class="py-3">
                  Thanks for getting in touch! Whether you need help using our
                  carbon calculators, want to discuss an audit, or just want to
                  know how you can be involved in our work, we’re happy to hear
                  from you. We’ll get back to you as soon as possible.
                </p>
                <table class="table">
                  <tbody>
                    <tr>
                      <td><strong>Organization:</strong></td>
                      <td>Africa Carbon Exchange</td>
                    </tr>
                    <tr>
                      <td><strong>Phone:</strong></td>
                      <td>+254 712 345 678</td>
                    </tr>
                    <tr>
                      <td><strong>Email:</strong></td>
                      <td>support@site.com</td>
                    </tr>
                    <tr>
                      <td><strong>Location:</strong></td>
                      <td>Kirichwa Road, Kilimani</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <form>
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="full-name"
                  placeholder="Full Name"
                />
                <label for="full-name">Full Name</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                />
                <label for="email">Email</label>
              </div>
              <div class="form-floating">
                <textarea
                  class="form-control"
                  placeholder="Enter Message"
                  id="message"
                  style="height: 120px"
                ></textarea>
                <label for="message">Message</label>
              </div>
              <div class="mt-3">
                <button class="btn btn-primary"><i class="fa fa-send me-2"></i>Send</button>
              </div>
            </form>
          </div>
          <div class="col-md-12 mb-5 mt-3">
            <img src="/theme/map.png" alt="map" class="w-100">
          </div>
        </div>
      </div>
    </div>

    <public-footer />
  </div>
</template>

<script>
import PublicHeader from "@/components/site/PublicHeader.vue";
import PublicFooter from "@/components/site/PublicFooter.vue";

export default {
  components: {
    PublicHeader,
    PublicFooter,
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },
};
</script>
