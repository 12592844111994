<template>
  <div>
    <public-header />

    <div class="site-content">
      
    </div>

    <public-footer />
  </div>
</template>

<script>
import PublicHeader from '@/components/site/PublicHeader.vue'
import PublicFooter from '@/components/site/PublicFooter.vue'

export default {
  components: {
    PublicHeader, PublicFooter
  }
};
</script>
